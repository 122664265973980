@keyframes gradientBackground {
  0%, 100% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
}


.custom-sider {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  /* Removed overflow-y: auto; from here */
  flex: 0 0 auto !important;
  max-width: 500px !important;
  min-width: 450px !important;
  width: 450px !important;
  background: linear-gradient(-45deg, #001529, #002140, #003366, #004080);
  background-size: 400% 400%;
  animation: gradientBackground 15s ease infinite;
  transition: width 0.2s ease;
  background-color: #001529;
}
.custom-sider::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.logo-container {
  padding: 16px 0 0 16px;
  background: #001529;
  text-align: left;
}

.lottie-logo {
  width: 100%;
  max-width: 300px;
}

.client-info {
  padding: 16px;
  background-color: transparent;
  color: white;
}

.domain-name {
  font-size: 16px;
  margin-bottom: 10px;
}

.domain-name .anticon {
  margin-right: 8px;
}

.stats-container {
  margin-top: 10px;
}

.stat-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  color: white;
}

.stat-item .anticon {
  margin-right: 8px;
  color: white;
}

.stat-item .ant-typography {
  color: white !important;
}

.custom-menu {
  background: transparent !important;
  border-right: 0;
  flex-grow: 1;
  transition: all 0.2s ease;
  width: 100%;
}

.custom-menu .ant-menu-item {
  position: relative;
  overflow: visible;
  height: auto !important;
  line-height: 1.5 !important;
  padding: 10px 16px !important;
  margin: 0 !important;
  background: transparent;
  transition: background-color 0.3s ease, all 0.3s ease;
  white-space: normal !important;
}

.custom-menu .ant-menu-item a {
  display: block;
  color: rgba(255, 255, 255, 0.65);
}

.custom-menu .ant-menu-item:hover,
.custom-menu .ant-menu-item-selected {
  background-color: rgba(24, 144, 255, 0.3) !important;
  backdrop-filter: blur(5px);
}

.custom-menu .ant-menu-item:hover a,
.custom-menu .ant-menu-item-selected a {
  color: #ffffff;
}

.menu-item-label,
.menu-item-description {
  transition: opacity 0.2s ease;
  display: block;
  white-space: normal;
  word-wrap: break-word;
}

.menu-item-label {
  font-size: 17.5px;
  line-height: 1.5;
}

.custom-menu .ant-menu-item .anticon {
  font-size: 20px;
  margin-right: 10px;
}

.menu-item-description {
  font-size: 15px;
  line-height: 1.3;
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: opacity 0.3s, max-height 0.3s;
}

.custom-menu .ant-menu-item:hover .menu-item-description {
  opacity: 1;
  max-height: 100px;
}

.site-layout {
  margin-left: 0%;
}

.site-content {
  /* Added overflow-y: auto; here or in .content-container */
  margin: 0;
  padding: 24px;
  min-height: 100vh;
  overflow-y: auto;
}

.content-container {
  background: #fff;
  padding: 24px;
  min-height: calc(100vh - 48px);
  overflow-y: auto;
}

@media (max-width: 768px) {
  .custom-sider {
    position: relative;
    width: 100% !important;
    max-width: 100%;
  }

  .site-layout {
    margin-left: 0;
  }
}

.menu-spacer {
  height: 60px;
  background: transparent;
}

.feedback-item {
  margin-top: 20px;
}

.feedback-item .ant-menu-item-icon {
  font-style: normal;
  font-size: 20px;
}

.ant-menu-title-content {
  width: 100%;
  white-space: normal;
}